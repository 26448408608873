const w = 100; //50; //20;
const h = 50; //25; //10;
const state = {
  physics: {
    w: w,
    h: h,

    particleMass: 0.1,
    restDistance: 10,

    windPeriod: 7000,
    windRange: 26,
    windBase: 19,
    damping: 0.3,
    g: 100,
    dt: Math.pow(18 / 1000, 2.0),
    windForceDirection: {
      x: Math.sin(-0.0000001),
      y: Math.cos(0.0000001),
      z: Math.sin(0.0000001),
    },
    leftConstraintActive: false,
    rightConstraintActive: false,
    upperConstraintActive: false,
    lowerConstraintActive: true,
    ballSize: 60,
    useInitialState: true,
  },
  rendering: {
    w: w,
    h: h,

    pixelRatio: 1,
    useDPR: true,

    fovMultiplier: 1.0,
    blending: "Additive", //"Normal", "No"
    colorMultiplier: "#ffffff",

    backgroundColor: "#1A34A7",
    ambientLightColor: "#ffffff",
    ambientLightIntensity: 0.22,
    spotLightColor: "#404040",
    spotLightIntensity: 1,
    spotLightAngle: 1.4,
    componentWidth: window.innerWidth,
    componentHeight: window.innerHeight,

    adaptToScreen: true,
    referenceWidth: 1728,
    referenceHeight: 909,
  },
};

export function getState() {
  return state;
}
