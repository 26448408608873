// import * as THREE from "three";

class ClothMaterial {
  constructor(texture, blending, colorMultiplier) {
    const color = new THREE.Color("#1A34A7");

    this.blendingMap = {
      Additive: THREE.AdditiveBlending,
      Normal: THREE.NormalBlending,
      No: THREE.NoBlending,
    };

    this.clothMaterial = new THREE.MeshLambertMaterial({
      map: texture,
      side: THREE.FrontSide,
      color: new THREE.Color(colorMultiplier),
      // alphaTest: 0.1,
      // depthWrite: false,
      // depthTest: false,
      transparent: true,
      blending: this.blendingMap[blending],

      // This stencil test allows to write to a pixel only once. If written,
      // it increments stencil buffer value, and all other pixels will bring non equal value here.
      // This fixes situations when front-triangle lies over another front-triangle and due to
      // additive blending doubles transparency of a pixel, which looks wrong

      stencilWrite: true,
      stencilFunc: THREE.EqualStencilFunc,
      stencilZPass: THREE.IncrementStencilOp,
    });

    this.customDepthMaterial = new THREE.MeshDepthMaterial({
      depthPacking: THREE.RGBADepthPacking,
      map: texture,

      transparent: true,
      // alphaTest: 0.1,
    });
  }

  setBlending(blending) {
    this.clothMaterial.blending = this.blendingMap[blending];
  }

  setMultiplierColor(color) {
    this.clothMaterial.color.set(color);
  }

  toggleTransparency() {
    this.clothMaterial.transparent = !this.clothMaterial.transparent;
    // if (!this.clothMaterial.transparent)
    //   this.clothMaterial.blending = THREE.NormalBlending;
    // else this.clothMaterial.blending = THREE.AdditiveBlending;
  }
}

class ClothMesh {
  constructor(particleSurface, material) {
    this.particleSurface = particleSurface;
    this.material = material;
    this.mesh = new THREE.Mesh(
      particleSurface.geometry,
      material.clothMaterial,
    );
    // object.position.set(0, 0, 0);
    // object.scale.x = 1;
    // object.scale.y = 1;
    this.mesh.castShadow = true;
    this.mesh.customDepthMaterial = material.customDepthMaterial;
  }

  setBlending(blending) {
    this.material.setBlending(blending);
  }

  setMultiplierColor(color) {
    this.material.setMultiplierColor(color);
  }

  toggleTransparency() {
    this.material.toggleTransparency();
  }
}

export { ClothMaterial, ClothMesh };
