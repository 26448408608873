// import * as THREE from "three";

const color = new THREE.Color("#1A34A7");
class ClothComponent {
  constructor(scene, dynamics) {
    this.scene = scene;
    this.dynamics = dynamics;
  }
  setBlending(blending) {
    this.scene.setBlending(blending);
  }

  setMultiplierColor(color) {
    this.scene.setMultiplierColor(color);
  }
  setBallSize(size) {
    this.dynamics.simulation.ball.size = size;
  }

  getCamera() {
    return this.scene.camera.object;
  }

  setMass(mass) {
    this.dynamics.simulation.setMass(mass);
  }

  setRestDistance(distance) {
    this.dynamics.simulation.setRestDistance(distance);
  }

  setBallPosition(position) {
    const ball = this.dynamics.simulation.ball;
    ball.position.copy(position);
  }
  serialize() {
    this.dynamics.simulation.particleSurface.serialize();
  }
  updateCameraFov(multiplier) {
    this.scene.updateCameraFov(multiplier);
  }

  updateBackground() {
    this.scene.updateBackground();
  }

  updateLights() {
    this.scene.lights.updateParameters();
  }

  update(time) {
    this.dynamics.update(time);
  }

  render(renderer) {
    // this.scene.background = new THREE.Color("#1A34A7");
    // renderer.setClearColor(color, 1.0);
    renderer.render(this.scene.scene, this.scene.camera.object);
  }

  toggleTransparency() {
    this.scene.clothMesh.toggleTransparency();
  }
}

export { ClothComponent };
