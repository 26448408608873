// import * as THREE from "three";

class PhysicalBall {
  constructor(size, position) {
    this.position = position;
    this.size = size;
  }
}

class ClothSimulation {
  constructor(ball, particleSurface, structuralConstraints, pinConstraints) {
    this.ball = ball;
    this.particleSurface = particleSurface;
    this.structuralConstraints = structuralConstraints;
    this.pinConstraints = pinConstraints;
    this.tmpForce = new THREE.Vector3();
    this.diff = new THREE.Vector3();
  }

  setRestDistance(restDistance) {
    this.structuralConstraints.setRestDistance(restDistance);
  }

  setMass(mass) {
    this.particleSurface.setMass(mass);
  }

  simulate(dt, damping, gravity, windForce) {
    var i, il, particles, particle;
    const { tmpForce, diff } = this;

    // Aerodynamics forces

    // eslint-disable-next-line
    var face,
      faces = this.particleSurface.faces,
      normal;

    particles = this.particleSurface.particles;

    for (i = 0, il = faces.length; i < il; i++) {
      face = faces[i];
      normal = face.normal;

      tmpForce.copy(normal).normalize().multiplyScalar(normal.dot(windForce));
      particles[face.a].addForce(tmpForce);
      particles[face.b].addForce(tmpForce);
      particles[face.c].addForce(tmpForce);
    }

    for (
      particles = this.particleSurface.particles, i = 0, il = particles.length;
      i < il;
      i++
    ) {
      particle = particles[i];
      particle.addForce(gravity);
      particle.integrate(dt, damping);
    }

    // Start Constraints
    this.structuralConstraints.satisfy();

    for (
      particles = this.particleSurface.particles, i = 0, il = particles.length;
      i < il;
      i++
    ) {
      const position = particles[i].position;
      const ball = this.ball;

      diff.subVectors(position, ball.position);
      if (diff.length() < ball.size) {
        // collided
        diff.normalize().multiplyScalar(ball.size);
        position.copy(ball.position).add(diff);
      }
    }
    this.pinConstraints.forEach(c => { c.satisfy()});
    this.particleSurface.updateSurfaceGeometry();
  }
}

export { PhysicalBall, ClothSimulation };
