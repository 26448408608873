import { Pane } from "tweakpane";
import { getState } from "./state";

const state = getState();
const input = document.createElement("input");

function readState(newState, state) {
  const badNames = [];
  const recursiveStateUpdate = (inState, toState) => {
    if (Array.isArray(toState)) {
      for (let i = 0; i < toState.length; i++) {
        if (typeof toState[i] === "object")
          recursiveStateUpdate(inState[i], toState[i]);
        else toState[i] = inState[i];
      }
    } else {
      Object.keys(toState).forEach((key) => {
        if (badNames.indexOf(key) !== -1) return;
        if (
          typeof inState[key] !== "undefined" &&
          typeof inState[key] !== "object"
        ) {
          toState[key] = inState[key];
        } else if (inState[key] && typeof inState[key] === "object") {
          recursiveStateUpdate(inState[key], toState[key]);
        }
      });
    }
  };

  recursiveStateUpdate(newState, state);
}

function updateEverything(component) {
  const referenceAspect =
    state.rendering.referenceWidth / state.rendering.referenceHeight;
  const aspect = window.innerWidth / window.innerHeight;
  const multiplier = state.rendering.adaptToScreen
    ? (state.rendering.fovMultiplier * referenceAspect) / aspect
    : state.rendering.fovMultiplier;

  component.setMass(state.physics.particleMass);
  component.setRestDistance(state.physics.restDistance);
  component.setBallSize(state.physics.ballSize);
  component.updateCameraFov(multiplier);
  component.updateBackground();
  component.updateLights();
}

export function buildGUI(component, renderer) {
  const pane = new Pane();
  const options = pane.addFolder({ title: "Options", expanded: false });
  const physics = options.addFolder({ title: "Physics", expanded: false });
  physics
    .addInput(state.physics, "particleMass", { min: 0.0, max: 2 })
    .on("change", () => {
      component.setMass(state.physics.particleMass);
    });
  physics
    .addInput(state.physics, "restDistance", { min: 2, max: 40 })
    .on("change", () => {
      component.setRestDistance(state.physics.restDistance);
    });
  physics.addInput(state.physics, "windPeriod", { min: 0.1, max: 100000 });
  physics.addInput(state.physics, "windRange", { min: 0.0, max: 200 });
  physics.addInput(state.physics, "windBase", { min: 0.0, max: 200 });
  physics.addInput(state.physics, "windForceDirection", {
    x: { min: -1, max: 1 },
    y: { min: -1, max: 1 },
    z: { min: -1, max: 1 },
  });
  physics.addInput(state.physics, "damping", { min: 0.0, max: 1.0 });
  physics.addInput(state.physics, "g", { min: 0.0, max: 400 });
  physics.addInput(state.physics, "leftConstraintActive");
  physics.addInput(state.physics, "rightConstraintActive");
  physics.addInput(state.physics, "upperConstraintActive");
  physics.addInput(state.physics, "lowerConstraintActive");
  physics
    .addInput(state.physics, "ballSize", { min: 0, max: 200 })
    .on("change", () => {
      component.setBallSize(state.physics.ballSize);
    });

  const rendering = options.addFolder({ title: "Rendering", expanded: false });

  rendering
    .addInput(state.rendering, "blending", {
      options: {
        Additive: "Additive",
        Normal: "Normal",
        No: "No",
      },
    })
    .on("change", () => {
      component.setBlending(state.rendering.blending);
    });
  rendering.addInput(state.rendering, "colorMultiplier").on("change", () => {
    component.setMultiplierColor(state.rendering.colorMultiplier);
  });

  rendering.addInput(state.rendering, "adaptToScreen").on("change", () => {
    const referenceAspect =
      state.rendering.referenceWidth / state.rendering.referenceHeight;
    const aspect = window.innerWidth / window.innerHeight;
    const multiplier = state.rendering.adaptToScreen
      ? (state.rendering.fovMultiplier * referenceAspect) / aspect
      : state.rendering.fovMultiplier;
    component.updateCameraFov(multiplier);
  });

  rendering
    .addInput(state.rendering, "referenceWidth", { min: 0, max: 4096, step: 1 })
    .on("change", () => {
      const referenceAspect =
        state.rendering.referenceWidth / state.rendering.referenceHeight;
      const aspect = window.innerWidth / window.innerHeight;
      const multiplier = state.rendering.adaptToScreen
        ? (state.rendering.fovMultiplier * referenceAspect) / aspect
        : state.rendering.fovMultiplier;
      component.updateCameraFov(multiplier);
    });

  rendering
    .addInput(state.rendering, "referenceHeight", {
      min: 0,
      max: 4096,
      step: 1,
    })
    .on("change", () => {
      const referenceAspect =
        state.rendering.referenceWidth / state.rendering.referenceHeight;
      const aspect = window.innerWidth / window.innerHeight;
      const multiplier = state.rendering.adaptToScreen
        ? (state.rendering.fovMultiplier * referenceAspect) / aspect
        : state.rendering.fovMultiplier;
      component.updateCameraFov(multiplier);
    });

  rendering.addInput(state.rendering, "useDPR").on("change", () => {
    const DPR =
      state.rendering.useDPR && window.devicePixelRatio
        ? Math.min(2, window.devicePixelRatio)
        : state.rendering.pixelRatio;
    renderer.setPixelRatio(DPR);
  });

  rendering
    .addInput(state.rendering, "pixelRatio", { min: 0.5, max: 5 })
    .on("change", () => {
      const DPR =
        state.rendering.useDPR && window.devicePixelRatio
          ? Math.min(2, window.devicePixelRatio)
          : state.rendering.pixelRatio;
      renderer.setPixelRatio(DPR);
    });

  // const DPR =
  //   state.rendering.useDPR && window.devicePixelRatio
  //     ? Math.min(2, window.devicePixelRatio)
  //     : state.rendering.pixelRatio;
  rendering
    .addInput(state.rendering, "fovMultiplier", { min: 0, max: 5 })
    .on("change", () => {
      const referenceAspect =
        state.rendering.referenceWidth / state.rendering.referenceHeight;
      const aspect = window.innerWidth / window.innerHeight;
      const multiplier = state.rendering.adaptToScreen
        ? (state.rendering.fovMultiplier * referenceAspect) / aspect
        : state.rendering.fovMultiplier;
      component.updateCameraFov(multiplier);
    });
  rendering.addInput(state.rendering, "backgroundColor").on("change", () => {
    component.updateBackground();
  });
  rendering.addInput(state.rendering, "ambientLightColor").on("change", () => {
    component.updateLights();
  });
  rendering
    .addInput(state.rendering, "ambientLightIntensity", { min: 0.0, max: 10.0 })
    .on("change", () => {
      component.updateLights();
    });
  rendering.addInput(state.rendering, "spotLightColor").on("change", () => {
    component.updateLights();
  });
  rendering
    .addInput(state.rendering, "spotLightIntensity", { min: 0.0, max: 30.0 })
    .on("change", () => {
      component.updateLights();
    });
  rendering
    .addInput(state.rendering, "spotLightAngle", { min: 0.0, max: Math.PI })
    .on("change", () => {
      component.updateLights();
    });

  options.addButton({ title: "Toggle Transparency" }).on("click", () => {
    component.toggleTransparency();
  });
  options.addButton({ title: "Serialize" }).on("click", () => {
    component.serialize();
  });

  options.addButton({ title: "saveState" }).on("click", () => {
    const a = document.createElement("a");
    const stateToExport = { ...state };
    let file = new Blob([JSON.stringify(stateToExport)], {
      type: "text/plain",
    });
    a.href = URL.createObjectURL(file);
    a.download = "state.json";
    a.click();
  });

  options.addButton({ title: "loadState" }).on("click", () => {
    input.type = "file";
    input.click();
  });
  options.addButton({ title: "Import" }).on("click", () => {
    if (input.files && input.files.length > 0) {
      input.files[0].text().then((res) => {
        let newState = JSON.parse(res);
        readState(newState, state);
        updateEverything(component);
        pane.refresh();
      });
    }
  });
}
