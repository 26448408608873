// import * as THREE from "three";

class DynamicState {
  constructor(parameters) {
    this.up = new THREE.Vector3(0, 1, 0);
    this.windForce = new THREE.Vector3();
    this.parameters = parameters;
    this.gravity = new THREE.Vector3(
      0,
      -parameters.g * parameters.particleMass,
      0,
    );
  }
  calculateGravity = () => {
    this.gravity.set(0, -this.parameters.g * this.parameters.particleMass, 0);
  };
}

class Dynamics {
  constructor(simulation, parameters, dynamicState) {
    this.simulation = simulation;
    this.parameters = parameters;
    this.dynamicState = dynamicState;
  }
  setWindDirection(x, y, z) {
    this.dynamicState.windForceDirection.set(x, y, z);
  }
  update(time) {
    this.simulation.simulate(
      this.parameters.dt,
      this.parameters.damping,
      this.dynamicState.gravity,
      this.dynamicState.windForce,
    );

    const windStrength =
      Math.cos(time / this.parameters.windPeriod) * this.parameters.windRange +
      this.parameters.windBase;

    this.dynamicState.windForce.copy(this.parameters.windForceDirection);
    this.dynamicState.windForce.normalize();
    this.dynamicState.windForce.multiplyScalar(windStrength);

    this.dynamicState.calculateGravity();
  }
}

export { Dynamics, DynamicState };
