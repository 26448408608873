// import * as THREE from "three";
//
class SceneLights {
  constructor(state) {
    const ambientLight = new THREE.AmbientLight(
      new THREE.Color(state.ambientLightColor),
      state.ambientLightIntensity,
    ); // soft white light

    const spotLight = new THREE.SpotLight(
      new THREE.Color(state.spotLightColor),
      state.spotLightIntensity,
    );
    spotLight.position.set(100, 10, 400);
    spotLight.target.position.set(40, -100, -100);
    spotLight.angle = state.spotLightAngle;
    // spotLight.penumbra = 0.05
    spotLight.decay = 0.2;
    spotLight.castShadow = true;
    spotLight.shadow.mapSize.width = 1024;
    spotLight.shadow.mapSize.height = 1024;

    this.spotLight = spotLight;
    this.ambientLight = ambientLight;
    this.lights = [spotLight, ambientLight];
    this.state = state;
  }
  updateParameters() {
    const light = this.ambientLight;
    const spotLight = this.spotLight;
    const state = this.state;

    light.color.set(state.ambientLightColor);
    light.intensity = state.ambientLightIntensity;

    spotLight.color.set(state.spotLightColor);
    spotLight.intensity = state.spotLightIntensity;
    spotLight.angle = state.spotLightAngle;
  }
}

class Camera {
  constructor(state) {
    this.state = state;
    const aspectRatio = state.componentWidth / state.componentHeight;
    const fieldOfView = (state.fovMultiplier * (40 * state.h)) / 50;
    const nearPlane = 1;
    const farPlane = 3000;
    this.state = state;
    this.object = new THREE.PerspectiveCamera(
      fieldOfView,
      aspectRatio,
      nearPlane,
      farPlane,
    );
    const camera = this.object;
    camera.position.z = 1400; //window.innerWidth < 768 ? 2800 : 1400;
    camera.position.y = state.h * 10; //500;
  }
  updateFov(multiplier) {
    const fieldOfView = (multiplier * (40 * this.state.h)) / 50;
    this.object.fov = Math.min(Math.max(0, fieldOfView), 120);
    this.object.updateProjectionMatrix();
  }
}

class Scene {
  constructor(state, lights, clothMesh, camera) {
    this.state = state;
    this.lights = lights;
    this.clothMesh = clothMesh;
    this.camera = camera;

    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(this.state.backgroundColor);
    this.lights.lights.forEach((light) => {
      this.scene.add(light);
    });

    this.scene.add(clothMesh.mesh);
    this.scene.add(camera.object);

    // sphere
    //
    // var ballGeo = new THREE.SphereGeometry(ballSize, 32, 32);
    // var ballMaterial = new THREE.MeshBasicMaterial({ color: 0xfffff });
    //
    // sphere = new THREE.Mesh(ballGeo, ballMaterial);
    // sphere.castShadow = false;
    // sphere.receiveShadow = false;
    // sphere.visible = true;
  }

  updateCameraFov(multiplier) {
    this.camera.updateFov(multiplier);
  }

  setBlending(blending) {
    this.clothMesh.setBlending(blending);
  }

  setMultiplierColor(color) {
    this.clothMesh.setMultiplierColor(color);
  }

  updateBackground() {
    this.scene.background = new THREE.Color(this.state.backgroundColor);
  }
}

export { Scene, SceneLights, Camera };
