import { ClothMaterial, ClothMesh } from "./ClothMesh";
import { SceneLights, Scene, Camera } from "./Scene";

export function createScene(state, particleSurface, clothTexture) {
  const clothMaterial = new ClothMaterial(
    clothTexture,
    state.blending,
    state.colorMultiplier,
  );
  const clothMesh = new ClothMesh(particleSurface, clothMaterial);
  const sceneLights = new SceneLights(state);
  const camera = new Camera(state);
  const scene = new Scene(state, sceneLights, clothMesh, camera);
  return scene;
}
