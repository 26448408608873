// import * as THREE from "three";
class StructuralConstraints {
  constructor(state, particleSurface) {
    this.state = state;
    this.particles = particleSurface.particles;
    this.diff = new THREE.Vector3();
    const constraints = [];
    const particles = this.particles;
    const index = particleSurface.index;
    var u, v;

    // Structural
    for (v = 0; v < state.h; v++) {
      for (u = 0; u < state.w; u++) {
        constraints.push([
          particles[particleSurface.index(u, v)],
          particles[particleSurface.index(u, v + 1)],
          state.restDistance,
        ]);

        constraints.push([
          particles[particleSurface.index(u, v)],
          particles[particleSurface.index(u + 1, v)],
          state.restDistance,
        ]);
      }
    }

    for (u = state.w, v = 0; v < state.h; v++) {
      constraints.push([
        particles[particleSurface.index(u, v)],
        particles[particleSurface.index(u, v + 1)],
        state.restDistance,
      ]);
    }

    for (v = state.h, u = 0; u < state.w; u++) {
      constraints.push([
        particles[particleSurface.index(u, v)],
        particles[particleSurface.index(u + 1, v)],
        state.restDistance,
      ]);
    }

    // While many systems use shear and bend springs,
    // the relaxed constraints model seems to be just fine
    // using structural springs.
    // Shear
    // var diagonalDist = Math.sqrt(state.restDistance * state.restDistance * 2);
    //
    // for (v = 0; v < state.h; v++) {
    //   for (u = 0; u < state.w; u++) {
    //     constraints.push([
    //       particles[particleSurface.index(u, v)],
    //       particles[particleSurface.index(u + 1, v + 1)],
    //       diagonalDist,
    //     ]);
    //
    //     constraints.push([
    //       particles[particleSurface.index(u + 1, v)],
    //       particles[particleSurface.index(u, v + 1)],
    //       diagonalDist,
    //     ]);
    //   }
    // }

    this.constraints = constraints;
  }

  satisfyConstraint(p1, p2, distance) {
    const { diff } = this;
    diff.subVectors(p2.position, p1.position);
    var currentDist = diff.length();
    if (currentDist === 0) return; // prevents division by 0
    var correction = diff.multiplyScalar(1 - distance / currentDist);
    var correctionHalf = correction.multiplyScalar(0.5);
    p1.position.add(correctionHalf);
    p2.position.sub(correctionHalf);
  }
  satisfy() {
    this.constraints.forEach((constraint) => {
      this.satisfyConstraint(constraint[0], constraint[1], constraint[2]);
    });
  }
  setRestDistance(restDistance) {
    const constraints = this.constraints;
    constraints.forEach((constraint) => {
      constraint[2] = restDistance;
    });
  }
}
class VerticalLeftPinConstraints {
  constructor(parameters, particles) {
    this.parameters = parameters;
    this.particles = particles;
    const pins = [];
    for (let i = 0; i < particles.length; i += parameters.w + 1) {
      pins.push(particles[i]);
    }
    this.pins = pins;
  }
  satisfy() {
    if (!this.parameters.leftConstraintActive) return;
    this.pins.forEach((p) => {
      p.position.copy(p.original);
      p.previous.copy(p.original);
    });
  }
}
class VerticalRightPinConstraints {
  constructor(parameters, particles) {
    this.parameters = parameters;
    this.particles = particles;
    const pins = [];
    for (let i = parameters.w; i < particles.length; i += parameters.w + 1) {
      pins.push(particles[i]);
    }
    this.pins = pins;
  }
  satisfy() {
    if (!this.parameters.rightConstraintActive) return;
    this.pins.forEach((p) => {
      p.position.copy(p.original);
      p.previous.copy(p.original);
    });
  }
}

class HorizontalUpPinConstraints {
  constructor(parameters, particles) {
    this.parameters = parameters;
    this.particles = particles;
    const pins = [];
    for (let i = particles.length - parameters.w; i < particles.length; i++) {
      pins.push(particles[i]);
    }
    this.pins = pins;
  }
  satisfy() {
    if (!this.parameters.upperConstraintActive) return;
    this.pins.forEach((p) => {
      p.position.copy(p.original);
      p.previous.copy(p.original);
    });
  }
}
class HorizontalDownPinConstraints {
  constructor(parameters, particles) {
    this.parameters = parameters;
    this.particles = particles;
    const pins = [];
    for (let i = 0; i <= parameters.w; i++) {
      pins.push(particles[i]);
    }
    this.pins = pins;
  }
  satisfy() {
    if (!this.parameters.lowerConstraintActive) return;
    this.pins.forEach((p) => {
      p.position.copy(p.original);
      p.previous.copy(p.original);
    });
  }
}

export {
  StructuralConstraints,
  HorizontalUpPinConstraints,
  HorizontalDownPinConstraints,
  VerticalLeftPinConstraints,
  VerticalRightPinConstraints,
};
