// import * as THREE from "three";
import { PhysicalBall } from "./ClothSimulation";
import {
  StructuralConstraints,
  HorizontalUpPinConstraints,
  HorizontalDownPinConstraints,
  VerticalRightPinConstraints,
  VerticalLeftPinConstraints,
} from "./Constraints";
import { ParticleSurface } from "./Particles";
import { ClothSimulation } from "./ClothSimulation";

export function createClothSimulation(state) {
  const particleSurface = new ParticleSurface(state);
  const structuralConstraints = new StructuralConstraints(
    state,
    particleSurface,
  );
  const downPinConstraints = new HorizontalDownPinConstraints(
    state,
    particleSurface.particles,
  );
  const upPinConstraints = new HorizontalUpPinConstraints(
    state,
    particleSurface.particles,
  );
  const leftPinConstraints = new VerticalLeftPinConstraints(
    state,
    particleSurface.particles,
  );
  const rightPinConstraints = new VerticalRightPinConstraints(
    state,
    particleSurface.particles,
  );

  const ball = new PhysicalBall(
    state.ballSize,
    new THREE.Vector3(0.0, 0.0, 0.0),
  );

  const clothSimulation = new ClothSimulation(
    ball,
    particleSurface,
    structuralConstraints,
    [downPinConstraints, upPinConstraints, leftPinConstraints, rightPinConstraints],
  );
  return clothSimulation;
}
