// import * as THREE from "three";
// import { ParametricGeometry } from "three/examples/jsm/geometries/ParametricGeometry";
import { buildGUI } from "./gui.js";
import { createClothSimulation } from "./clothComponent/simulation/SimulationFactory.js";
import { createDynamics } from "./clothComponent/dynamics/DynamicsFactory.js";
import { createScene } from "./clothComponent/scene/SceneFactory.js";
import { createClothComponent } from "./clothComponent/ComponentFactory.js";
import { getState } from "./state.js";

const canvas = document.getElementById("canvas");
// window.ParametricGeometry = ParametricGeometry;
// window.THREE = THREE;
// buildGUI(sceneManager);

const state = getState();

const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  antialias: true,
  alpha: true,
});
const DPR =
  state.rendering.useDPR && window.devicePixelRatio
    ? Math.min(2, window.devicePixelRatio)
    : state.rendering.pixelRatio;
renderer.setPixelRatio(DPR);
renderer.setSize(window.innerWidth, window.innerHeight);

renderer.gammaInput = true;
renderer.gammaOutput = true;
renderer.shadowMap.enabled = true;
renderer.shadowMap.needsUpdate = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;

const loader = new THREE.TextureLoader();
const clock = new THREE.Clock();
const raycaster = new THREE.Raycaster();
const mouse = new THREE.Vector3();

// loader.load("logo_EF_sign.png", (texture) => {
loader.load("logo-menu.png", (texture) => {
  texture.minFilter = THREE.LinearMipmapLinearFilter;
  texture.magFilter = THREE.LinearFilter;

  const simulation = createClothSimulation(state.physics);
  const dynamics = createDynamics(state.physics, simulation);
  const scene = createScene(
    state.rendering,
    simulation.particleSurface,
    texture,
  );
  const component = createClothComponent(scene, dynamics);
  const referenceAspect =
    state.rendering.referenceWidth / state.rendering.referenceHeight;
  const aspect = window.innerWidth / window.innerHeight;
  const multiplier = state.rendering.adaptToScreen
    ? (state.rendering.fovMultiplier * referenceAspect) / aspect
    : state.rendering.fovMultiplier;

  component.updateCameraFov(multiplier);
  buildGUI(component, renderer);
  component.setBallPosition(new THREE.Vector3(0, 300, 0));

  document.addEventListener("mousemove", (event) => {
    const camera = component.scene.camera.object;
    const halfFov = camera.fov / 2;
    const distance = camera.position.z;
    const aspect = camera.aspect;
    const cameraOffsetY = camera.position.y;
    const halfHeight = Math.tan((halfFov * Math.PI) / 180) * distance;
    const halfWidth = aspect * halfHeight;

    const x = event.clientX / window.innerWidth - 0.5;
    const y = -(event.clientY / window.innerHeight - 0.5);

    mouse.x = 2 * x * halfWidth;
    mouse.y = 2 * y * halfHeight + cameraOffsetY;

    mouse.z = -20;
  });

  document.addEventListener("touchmove", (event) => {


    const camera = component.scene.camera.object;
    const halfFov = camera.fov / 2;
    const distance = camera.position.z;
    const aspect = camera.aspect;
    const cameraOffsetY = camera.position.y;
    const halfHeight = Math.tan((halfFov * Math.PI) / 180) * distance;
    const halfWidth = aspect * halfHeight;

    const x = event.touches[0].clientX / window.innerWidth - 0.5;
    const y = -(event.touches[0].clientY / window.innerHeight - 0.5);

    mouse.x = 2 * x * halfWidth;
    mouse.y = 2 * y * halfHeight + cameraOffsetY;

    mouse.z = -20;
  });

  window.addEventListener("resize", () => {
    renderer.setSize(window.innerWidth, window.innerHeight);
    component.scene.camera.object.aspect =
      window.innerWidth / window.innerHeight;

    const referenceAspect =
      state.rendering.referenceWidth / state.rendering.referenceHeight;
    const aspect = window.innerWidth / window.innerHeight;
    const multiplier = state.rendering.adaptToScreen
      ? (state.rendering.fovMultiplier * referenceAspect) / aspect
      : state.rendering.fovMultiplier;

    component.updateCameraFov(multiplier);

    component.scene.camera.object.updateProjectionMatrix();
  });

  run(component);
});

function run(component) {
  requestAnimationFrame(() => {
    run(component);
  });
  raycaster.setFromCamera(mouse, component.getCamera());
  component.update(clock.getElapsedTime());
  renderer.setClearAlpha(0.3);
  component.render(renderer);
  component.setBallPosition(mouse);
}

// document.addEventListener("DOMContentLoaded", function () {
//   bindEventListeners();
//   render();
// });
//
// function bindEventListeners() {
//   window.onresize = resizeCanvas;
//   resizeCanvas();
// }
//
// function resizeCanvas() {
//   // canvas.style.width = "100%";
//   // canvas.style.height = "100%";
//   // canvas.width = canvas.offsetWidth;
//   // canvas.height = canvas.offsetHeight;
//   sceneManager.onWindowResize();
// }
//
// function render() {
//   requestAnimationFrame(render);
//   sceneManager.update();
// }
