// import * as THREE from "three";
import { initialString } from "./initial.js";

function computeFaceNormal(positions, face) {
  var cb = new THREE.Vector3(),
    ab = new THREE.Vector3();

  var vA = positions.a;
  var vB = positions.b;
  var vC = positions.c;

  cb.subVectors(vC, vB);
  ab.subVectors(vA, vB);
  cb.cross(ab);

  cb.normalize();

  face.normal.copy(cb);
}

function updateFaces(geometry, faces) {
  const position = geometry.attributes.position.array;
  const indices = geometry.index.array;

  const numFaces = geometry.index.count / 3.0;

  for (let i = 0; i < numFaces; i++) {
    const index0 = indices[3 * i];
    const index1 = indices[3 * i + 1];
    const index2 = indices[3 * i + 2];

    const position0 = new THREE.Vector3(
      position[3 * index0],
      position[3 * index0 + 1],
      position[3 * index0 + 2],
    );
    const position1 = new THREE.Vector3(
      position[3 * index1],
      position[3 * index1 + 1],
      position[3 * index1 + 2],
    );
    const position2 = new THREE.Vector3(
      position[3 * index2],
      position[3 * index2 + 1],
      position[3 * index2 + 2],
    );

    // faces.push([position0, position1, position2]);
    const facePositions = { a: position0, b: position1, c: position2 };
    const face = faces[i];
    computeFaceNormal(facePositions, face);
  }
}
function getFaces(geometry) {
  const position = geometry.attributes.position.array;
  const indices = geometry.index.array;

  const numFaces = geometry.index.count / 3.0;
  const faces = [];

  for (let i = 0; i < numFaces; i++) {
    const index0 = indices[3 * i];
    const index1 = indices[3 * i + 1];
    const index2 = indices[3 * i + 2];

    const position0 = new THREE.Vector3(
      position[3 * index0],
      position[3 * index0 + 1],
      position[3 * index0 + 2],
    );
    const position1 = new THREE.Vector3(
      position[3 * index1],
      position[3 * index1 + 1],
      position[3 * index1 + 2],
    );
    const position2 = new THREE.Vector3(
      position[3 * index2],
      position[3 * index2 + 1],
      position[3 * index2 + 2],
    );

    // faces.push([position0, position1, position2]);
    const facePositions = { a: position0, b: position1, c: position2 };
    const face = {
      a: index0,
      b: index1,
      c: index2,
      normal: new THREE.Vector3(),
    };
    computeFaceNormal(facePositions, face);
    faces.push(face);
  }
  return faces;
}

class Particle {
  constructor(x, y, mass, clothFunction) {
    this.position = new THREE.Vector3();
    this.previous = new THREE.Vector3();
    this.original = new THREE.Vector3();
    this.a = new THREE.Vector3(0, 0, 0); // acceleration
    this.mass = mass;
    this.invMass = 1 / mass;
    this.tmp = new THREE.Vector3();
    this.tmp2 = new THREE.Vector3();

    // init

    clothFunction(x, y, this.position); // position
    clothFunction(x, y, this.previous); // previous
    clothFunction(x, y, this.original);
  }

  // Force -> Acceleration
  addForce(force) {
    this.a.add(this.tmp2.copy(force).multiplyScalar(this.invMass));
  }

  setMass(mass) {
    this.mass = mass;
    this.invMass = 1 / mass;
  }

  // Performs Verlet integration

  integrate(timesq, damping) {
    var newPos = this.tmp.subVectors(this.position, this.previous);
    newPos.multiplyScalar(1.0 - damping).add(this.position);
    newPos.add(this.a.multiplyScalar(timesq));

    this.tmp = this.previous;
    this.previous = this.position;
    this.position = newPos;

    this.a.set(0, 0, 0);
  }
}

class ParticleSurface {
  constructor(state) {
    this.state = state;
    var particles = [];
    var u, v;

    function plane(width, height) {
      return function (u, v, target) {
        var x = (u - 0.5) * width;
        var y = (v + 0.5) * height;
        var z = 0;

        target.set(x, y, z);
      };
    }
    const clothFunction = plane(
      state.restDistance * state.w,
      state.restDistance * state.h,
    );

    // Create particles
    for (v = 0; v <= state.h; v++) {
      for (u = 0; u <= state.w; u++) {
        particles.push(
          new Particle(
            u / state.w,
            v / state.h,
            state.particleMass,
            clothFunction,
          ),
        );
      }
    }

    this.particles = particles;
    this.deserialize();
    this.geometry = new ParametricGeometry(clothFunction, state.w, state.h);
    this.faces = getFaces(this.geometry);
  }

  serialize() {
    const serialized = { position: [], previous: [] };
    this.particles.forEach((p) => {
      serialized.position.push({
        x: p.position.x.toFixed(3),
        y: p.position.y.toFixed(3),
        z: p.position.z.toFixed(3),
      });

      serialized.previous.push({
        x: p.previous.x.toFixed(3),
        y: p.previous.y.toFixed(3),
        z: p.previous.z.toFixed(3),
      });
    });

    console.log(JSON.stringify(serialized));
  }

  deserialize() {
    if (this.state.useInitialState) {
      const initial = JSON.parse(initialString);
      this.particles.forEach((p, index) => {
        const toPosition = initial.position[index];
        const toPrevious = initial.previous[index];
        p.position.set(
          parseFloat(toPosition.x),
          parseFloat(toPosition.y),
          parseFloat(toPosition.z),
        );
        p.previous.set(
          parseFloat(toPrevious.x),
          parseFloat(toPrevious.y),
          parseFloat(toPrevious.z),
        );
      });
    }
  }

  updateSurfaceGeometry() {
    const p = this.particles;
    for (var i = 0, il = p.length; i < il; i++) {
      // this.clothGeometry.vertices[i].copy(p[i].position);
      const startIndex = 3 * i;
      this.geometry.attributes.position.array[startIndex] = p[i].position.x;
      this.geometry.attributes.position.array[startIndex + 1] = p[i].position.y;
      this.geometry.attributes.position.array[startIndex + 2] = p[i].position.z;
    }

    this.geometry.attributes.position.needsUpdate = true;
    this.geometry.attributes.normal.needsUpdate = true;

    updateFaces(this.geometry, this.faces);
    this.geometry.computeVertexNormals();
  }

  setMass(mass) {
    const particles = this.particles;
    particles.forEach((particle) => {
      particle.setMass(mass);
    });
  }
  index(u, v) {
    return u + v * (this.state.w + 1);
  }
}

export { ParticleSurface };
